<template>
  <div>
    <KTPortlet title="Form Builder">
      <!-- <template v-slot:toolbar v-if="start !== null && end !== null">
          {{ fstart }} - {{ fend }}
        </template> -->
      <template v-slot:toolbar>
        <v-btn color="info" @click="editNewForm(null)" style="text-align: center;" class="ma-1">NEW FORM
        </v-btn>
      </template>
      <template slot="body">
        <!-- <b-button @click="openBuilder" style="text-align: center;" variant="info text-light" class="ma-1">OPEN
        </b-button> -->
        <Loader v-if="forms === null" :newStyle="2" :fields="[
          'Status',
          'Number',
          'Total',
          'Amount Due',
          'Created Date',
          'Due Date',
          ''
        ]"></Loader>
        <div v-else-if="forms.length === 0" style="width: 100%; text-align: center">
          No forms to display
        </div>
        <div v-else>
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Name</b-th>
                <b-th>Status</b-th>
                <b-th>Description</b-th>
                <!-- <b-th>Pending Changes</b-th> -->
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="form in forms" v-bind:key="form.id">
                <b-td>{{ form.name }}
                  <v-chip v-if="
                    form.draftConfig !== null
                  " class="ma-2" :style="
  `background: var(--warning); color: #fff; margin-left: 10px;`
">has pending changes</v-chip>
                </b-td>
                <b-td>
                  <v-chip v-if="
                    form.active === true
                  " class="ma-2" :style="
  `background: var(--success); color: #fff; margin-right: 10px;`
">public</v-chip>
                  <v-chip v-else class="ma-2" :style="
                    `background: var(--danger); color: #fff; margin-right: 10px;`
                  ">private</v-chip>
                </b-td>
                <b-td>{{ form.description }}</b-td>
                <!-- <b-td>
                  <v-chip v-if="
                    form.draftConfig !== null
                  " class="ma-2" :style="
  `background: var(--warning); color: #fff; margin-right: 10px;`
">yes</v-chip>
                </b-td> -->
                <b-td>
                  <v-btn color="info" @click="editNewForm(form.id)">VIEW/EDIT</v-btn>
                  <v-btn color="info"
                    @click="$router.push(`/Communication/${ $route.params.serviceId }/Forms/${ form.id }/Results`)"
                    style="margin-left: 10px;">RESULTS</v-btn>
                  <!-- <v-btn color="info" @click="openBuilder(form.id)" style="margin-left: 10px;">DESIGN</v-btn> -->
                  <div style="display: inline-block; padding: 5px; padding-left: 10px;"
                    v-if="getUrlForForm(form.renderAppId, form.id) !== null">
                    <a :href="getUrlForForm(form.renderAppId, form.id)" target="_blank">
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
    </KTPortlet>
    <div id="form-builder">

    </div>
    <div v-if="builderOpen" style="position: fixed;left: 10px;bottom: 10px;z-index: 999999999;opacity: 0.3;">
      Powered by BetterPortal Forms</div>
    <div v-if="builderOpen" class="" x-ms-format-detection="none" data-touch-id="16"
      style="z-index: 20000000;top: 20px;right: 20px;background: #343a40f2;border-radius: 30px;position: fixed;">
      <div class="" style="transform: translate3d(0px, 0px, 0px);">
        <div class=""
          style="display: flex; flex-direction: row; flex-wrap: nowrap;align-items: center;align-content: center;justify-content: center;">
          <div :style="`background-image: url(${ icon })`" class="menuItem" style="cursor: default"></div>
          <div class="menuBreaker"></div>
          <div class="menuItem menuText">
            <h5 class="text">{{ builderEditForm.name }}</h5>
          </div>
          <!-- <div class="menuBreaker"></div>
          <div class="menuItem" @click="editDesignForm(builderEditFormId)"><i
              class="fas fa-eye-dropper paint-fancy-color" />
          </div> -->
          <div class="menuBreaker"></div>
          <div class="menuItem" @click="builderHasChanges ? builder.save() : () => { }"><i
              :class="`fas fa-${ builderIsSaving ? 'sync-alt fa-spin' : 'save' } ${ builderHasChanges ? 'text-success' : '' }`"
              :style="`opacity: ${ !builderHasChanges || !builderIsDeploying ? '1' : '0.3' }`"></i>
          </div>
          <div class="menuBreaker" v-if="builderEditForm.draftConfig !== null"></div>
          <div class="menuItem" v-if="builderEditForm.draftConfig !== null"
            @click="builderHasChanges || builderIsSaving || builderIsDeploying ? () => { } : deployDraft()"><i
              :class="`fas fa-${ builderIsDeploying ? 'sync-alt fa-spin' : 'upload' } ${ builderHasChanges ? '' : 'text-success' }`"
              :style="`opacity: ${ builderHasChanges || builderIsSaving || builderIsDeploying ? '0.3' : '1' }`"></i>
          </div>
          <div class="menuBreaker"></div>
          <div class="menuItem" @click="!builderIsSaving || !builderIsDeploying ? builder.close() : () => { }"><i
              :class="`fas fa-times text-danger`"
              :style="`opacity: ${ !builderIsSaving || !builderIsDeploying ? '1' : '0.3' }`"></i>
          </div>
        </div>
      </div>
    </div>
    <YSIDEBAR ref="formConfig" @hide="hideFormConfig(false)">
      <template slot="header">
        <h3 style="padding: 0 20px 0 20px" v-if="configEditFormId === null">New Form</h3>
        <h3 style="padding: 0 20px 0 20px" v-else>Edit Form</h3>
      </template>

      <div style="padding: 20px;">
        <v-form role="form" ref="formConfigForm" v-model="configEditFormValid" @submit="submitForm" lazy-validation>
          <Loader v-if="configEditForm === null" :inContent="true">Loading...</Loader>
          <template v-else>
            <v-text-field v-model="configEditForm.name" name="Name" autocapitalize="on" label="Name" autocorrect="on"
              spellcheck="true" type="text" :rules="$vrules.basicText('Name')" :required="true"></v-text-field>
            <v-text-field v-model="configEditForm.description" name="Description" autocapitalize="on"
              label="Description" autocorrect="on" spellcheck="true" type="text" :required="false">
            </v-text-field>
            <template v-if="configEditFormId !== null">
              <v-checkbox :disabled="configEditForm.config.config === null" v-model="configEditForm.active"
                :label="`Public${ configEditForm.config.config === null ? ' (Design the form first)' : '' }`">
              </v-checkbox>
            </template>
            <v-select v-if="hostsSelector.length > 1" v-model="configEditForm.renderAppId" :items="hostsSelector"
              :required="false" label="Form URL">
            </v-select>
            <v-text-field v-if="hostsSelector.length > 1 && selectedHostCanCustom" v-model="configEditForm.urlKey"
              name="Custom URL Key" autocapitalize="off" label="Custom URL Key" autocorrect="off" spellcheck="false"
              type="text" :required="false">
            </v-text-field>
            <div v-if="configEditFormId !== null && configEditForm.tasks.length !== 0">
              <v-select v-model="configEditForm.tasks[0].action" :items="['none', 'email', 'webhook']" :required="false"
                label="Form completion action">
              </v-select>
              <v-text-field v-if="configEditForm.tasks[0].action !== 'none'" v-model="configEditForm.tasks[0].value"
                autocapitalize="off" label="Action value" autocorrect="off" spellcheck="true" type="text"
                :required="false">
              </v-text-field>
            </div>
            <v-btn :color="configEditFormValid ? 'success' : 'info'"
              :disabled="!configEditFormValid || builderIsDeploying" @click="submitForm"
              style="margin-right: 10px;margin-bottom: 10px;" class="ma-1">SAVE
            </v-btn>
            <v-btn v-if="configEditFormId !== null" color="info" @click="openBuilder(configEditFormId)"
              :disabled="builderIsDeploying" style="margin-right: 10px;margin-bottom: 10px;">
              DESIGN</v-btn>
            <!-- <v-btn v-if="configEditFormId !== null" color="info" @click="openBuilder(configEditFormId)"
              style="margin-left: 10px;">
            </v-btn> -->
            <v-btn v-if="configEditFormId !== null" @click="editDesignForm(configEditFormId)"
              :disabled="builderIsDeploying" style="margin-right: 10px;margin-bottom: 10px;"
              class="paint-fancy-bgcolor">
              <!-- <div class="menuItem"><i class="fas fa-eye-dropper paint-fancy-color" /> </div> -->
              Styles
            </v-btn>
            <v-btn v-if="configEditFormId !== null && configEditForm.draftConfig !== null" color="warning"
              :disabled="builderIsDeploying" @click="deployDraftStraight(configEditFormId)"
              style="margin-right: 10px;margin-bottom: 10px;">
              DEPLOY CHANGES</v-btn>
          </template>
        </v-form>
      </div>
    </YSIDEBAR>
    <YSIDEBAR ref="formDesign" @hide="hideFormDesign(false)" style="z-index: 99999999;">
      <template slot="header">
        <h3 style="padding: 0 20px 0 20px">Edit Form Style</h3>
      </template>

      <div style="padding: 20px;">
        <v-form role="form" ref="formDesignForm" v-model="designEditFormValid" @submit="submitDesignForm"
          lazy-validation>
          <Loader v-if="designEditForm === null" :inContent="true">Loading...</Loader>
          <template v-else>
            <label>Form Type</label>
            <b-form-select v-model="designEditForm.config.renderType" :options="['autoscroll', 'chat', 'classic']"
              :required="true" label="Form Type">
            </b-form-select>
            <v-checkbox v-model="designEditForm.config.properties.autoFocus" label="Auto focus">
            </v-checkbox>
            <v-checkbox v-model="designEditForm.config.properties.noBranding" :disabled="true" label="Hide Branding">
            </v-checkbox>

            <v-card
              style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
              <h5>
                Colour
              </h5>
              <v-color-picker v-model="designEditForm.config.properties.color" name="Colour" autocapitalize="off"
                label="Colour" autocorrect="off" spellcheck="false" type="color" :xrules="$vrules.basicText('Name')"
                dot-size="25" mode="hexa" :required="false"></v-color-picker>
            </v-card>

            <v-card
              style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
              <h5>
                Background
              </h5>
              <v-color-picker v-model="designEditForm.config.properties.background.color" name="Background Colour"
                autocapitalize="off" label="Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              <v-text-field v-model="designEditForm.config.properties.background.url" name="Background URL"
                autocapitalize="off" label="URL" autocorrect="off" spellcheck="false" type="url"
                :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              <v-text-field v-model="designEditForm.config.properties.background.opacity" name="Background Opacity"
                autocapitalize="off" label="Opacity" autocorrect="off" spellcheck="false" type="number" :min="0"
                :max="100" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              <label>Position</label>
              <b-form-select v-model="designEditForm.config.properties.positioning"
                :options="['auto', '100% auto', 'auto 100%', 'cover', 'contain', 'repeat']" :required="false"
                label="Position">
              </b-form-select>
            </v-card>

            <v-card
              style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
              <h5>
                Inputs
              </h5>
              Background Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.backgroundColor" name="Background Colour"
                autocapitalize="off" label="Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              Border Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.borderColor" name="Background Colour"
                autocapitalize="off" label="Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              Text Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.textColor" name="Background Colour"
                autocapitalize="off" label="Text Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              Error Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.errorColor" name="Background Colour"
                autocapitalize="off" label="Error Colour" autocorrect="off" spellcheck="false" type="color"
                dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
              </v-color-picker>
              Agree Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.agreeColor" name="Background Colour"
                autocapitalize="off" label="Agree Colour" autocorrect="off" spellcheck="false" type="color"
                dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
              </v-color-picker>
              Decline Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.declineColor" name="Background Colour"
                autocapitalize="off" label="Decline Colour" autocorrect="off" spellcheck="false" type="color"
                dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
              </v-color-picker>
              Selection Colour
              <v-color-picker v-model="designEditForm.config.properties.inputs.selectionColor" name="Background Colour"
                autocapitalize="off" label="Selection Colour" autocorrect="off" spellcheck="false" type="color"
                dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
              </v-color-picker>

              <v-text-field v-model="designEditForm.config.properties.inputs.roundness" name="Inputs Roundness"
                autocapitalize="off" label="Roundness" autocorrect="off" spellcheck="false" type="number" :min="0"
                :max="25" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              <v-text-field v-model="designEditForm.config.properties.inputs.borderSize" name="Inputs Border Size"
                autocapitalize="off" label="Border Size" autocorrect="off" spellcheck="false" type="number" :min="0"
                :max="25" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
            </v-card>

            <v-card
              style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
              <h5>
                Buttons
              </h5>
              Base Colour
              <v-color-picker v-model="designEditForm.config.properties.buttons.baseColor" name="Background Colour"
                autocapitalize="off" label="Base Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              Text Colour
              <v-color-picker v-model="designEditForm.config.properties.buttons.textColor" name="Background Colour"
                autocapitalize="off" label="Text Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                mode="hexa" :xrules="$vrules.basicText('Name')" :required="false"></v-color-picker>
              Finish Colour
              <v-color-picker v-model="designEditForm.config.properties.buttons.finishColor" name="Finish Colour"
                autocapitalize="off" label="Finish Colour" autocorrect="off" spellcheck="false" type="color"
                dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
              </v-color-picker>

              <v-text-field v-model="designEditForm.config.properties.buttons.roundness" name="Buttons Roundness"
                autocapitalize="off" label="Roundness" autocorrect="off" spellcheck="false" type="number" :min="0"
                :max="25" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              <label>Mode</label>
              <b-form-select v-model="designEditForm.config.properties.buttons.mode" :options="['fill', 'outline']"
                :required="false" label="Mode">
              </b-form-select>
            </v-card>

            <template
              v-if="designEditForm.config.renderType === 'classic' || designEditForm.config.renderType === 'autoscroll'">
              <v-checkbox v-model="designEditForm.config.properties.hideRequiredIndicator"
                label="Hide required indicator">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.showProgressbar" label="Show progress bar">
              </v-checkbox>
            </template>
            <template
              v-if="designEditForm.config.renderType === 'autoscroll' || designEditForm.config.renderType === 'chat'">
              <v-checkbox v-model="designEditForm.config.properties.showScrollbar" label="Show scrollbar">
              </v-checkbox>
            </template>
            <template v-if="designEditForm.config.renderType === 'autoscroll'">
              <label>Direction</label>
              <b-form-select v-model="designEditForm.config.properties.direction" :options="['horizontal', 'vertical']"
                :required="false" label="Direction">
              </b-form-select>
              <label>Virtical Alignment</label>
              <b-form-select v-model="designEditForm.config.properties.verticalAlignment"
                :options="['top', 'middle', 'bottom']" :required="false" label="Virtical Alignment">
              </b-form-select>
              <v-checkbox v-model="designEditForm.config.properties.hidePast" label="Hide past questions/answers">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.hideUpcoming" label="Hide upcoming">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.showSeparateSubmit" label="Show separate submit">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.showPreviousButton" label="Show previous button">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.disableScrolling" label="Disable scrolling">
              </v-checkbox>
              <label>Show navigation</label>
              <b-form-select v-model="designEditForm.config.properties.showNavigation"
                :options="['auto', 'always', 'never']" :required="false" label="Show navigation">
              </b-form-select>
              <v-card
                style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
                <h5>
                  Navigation
                </h5>
                Background Colour
                <v-color-picker v-model="designEditForm.config.properties.navigation.backgroundColor"
                  name="Background Colour" autocapitalize="off" label="Background Colour" autocorrect="off"
                  spellcheck="false" type="color" dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')"
                  :required="false">
                </v-color-picker>
                Text Colour
                <v-color-picker v-model="designEditForm.config.properties.navigation.textColor" name="Text Colour"
                  autocapitalize="off" label="Text Colour" autocorrect="off" spellcheck="false" type="color"
                  dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
                Progress Bar Colour
                <v-color-picker v-model="designEditForm.config.properties.navigation.progressbarColor"
                  name="Progress Bar Colour" autocapitalize="off" label="Progress Bar Colour" autocorrect="off"
                  spellcheck="false" type="color" dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')"
                  :required="false">
                </v-color-picker>
              </v-card>
            </template>
            <template v-if="designEditForm.config.renderType === 'chat'">
              <label>Display</label>
              <b-form-select v-model="designEditForm.config.properties.display" :options="['inline', 'button', 'page']"
                :required="false" label="Display">
              </b-form-select>
              <v-checkbox v-model="designEditForm.config.properties.autoOpen" label="Auto open">
              </v-checkbox>
              <v-checkbox v-model="designEditForm.config.properties.fullWidth" label="Full width">
              </v-checkbox>
              <v-card
                style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
                <h5>
                  Avatar
                </h5>
                <label>Type</label>
                <b-form-select v-model="designEditForm.config.properties.avatar.type" :options="['avataaar', 'image']"
                  :required="false" label="Type">
                </b-form-select>
                <v-text-field v-if="designEditForm.config.properties.avatar.type === 'image'"
                  v-model="designEditForm.config.properties.avatar.image" name="Avatar Image" autocapitalize="off"
                  label="Avatar Image" autocorrect="off" spellcheck="false" type="url"
                  :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              </v-card>
              <v-card
                style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
                <h5>
                  Questions
                </h5>
                <label>Alignment</label>
                <b-form-select v-model="designEditForm.config.properties.questions.alignment"
                  :options="['left', 'right']" :required="false" label="Alignment">
                </b-form-select>
                <v-text-field v-model="designEditForm.config.properties.questions.roundness" name="Question Roundness"
                  autocapitalize="off" label="Roundness" autocorrect="off" spellcheck="false" type="number" :min="0"
                  :max="25" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
                Background Colour
                <v-color-picker v-model="designEditForm.config.properties.questions.backgroundColor"
                  name="Background Colour" autocapitalize="off" label="Background Colour" autocorrect="off"
                  spellcheck="false" type="color" dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')"
                  :required="false">
                </v-color-picker>
                Border Colour
                <v-color-picker v-model="designEditForm.config.properties.questions.borderColor" name="Border Colour"
                  autocapitalize="off" label="Border Colour" autocorrect="off" spellcheck="false" type="color"
                  dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
                Text Colour
                <v-color-picker v-model="designEditForm.config.properties.questions.textColor" name="Text Colour"
                  autocapitalize="off" label="Text Colour" autocorrect="off" spellcheck="false" type="color"
                  dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
              </v-card>
              <v-card
                style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
                <h5>
                  Answers
                </h5>
                <label>Alignment</label>
                <b-form-select v-model="designEditForm.config.properties.answers.alignment" :options="['left', 'right']"
                  :required="false" label="Alignment">
                </b-form-select>
                <v-text-field v-model="designEditForm.config.properties.answers.roundness" name="Question Roundness"
                  autocapitalize="off" label="Roundness" autocorrect="off" spellcheck="false" type="number" :min="0"
                  :max="25" :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
                Background Colour
                <v-color-picker v-model="designEditForm.config.properties.answers.backgroundColor"
                  name="Background Colour" autocapitalize="off" label="Background Colour" autocorrect="off"
                  spellcheck="false" type="color" dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')"
                  :required="false">
                </v-color-picker>
                Border Colour
                <v-color-picker v-model="designEditForm.config.properties.answers.borderColor" name="Border Colour"
                  autocapitalize="off" label="Border Colour" autocorrect="off" spellcheck="false" type="color"
                  dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
                Text Colour
                <v-color-picker v-model="designEditForm.config.properties.answers.textColor" name="Text Colour"
                  autocapitalize="off" label="Text Colour" autocorrect="off" spellcheck="false" type="color"
                  dot-size="25" mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
                <v-text-field v-model="designEditForm.config.properties.answers.avatarImage" name="Avatar Image"
                  autocapitalize="off" label="Avatar Image" autocorrect="off" spellcheck="false" type="url"
                  :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              </v-card>
              <v-card
                style="padding: 10px;background: none;box-shadow: none;border: 1px solid var(--v-secondary-darken2); margin-bottom: 10px;">
                <h5>
                  Chat Button
                </h5>
                Colour
                <v-color-picker v-model="designEditForm.config.properties.chatButton.color" name="Colour"
                  autocapitalize="off" label="Colour" autocorrect="off" spellcheck="false" type="color" dot-size="25"
                  mode="hexa" :xrules="$vrules.basicText('Name')" :required="false">
                </v-color-picker>
                <v-text-field v-model="designEditForm.config.properties.chatButton.image" name="Image"
                  autocapitalize="off" label="Image" autocorrect="off" spellcheck="false" type="url"
                  :xrules="$vrules.basicText('Name')" :required="false"></v-text-field>
              </v-card>
            </template>
            <template v-if="designEditForm.config.renderType === 'classic'">
              <label>Mode</label>
              <b-form-select v-model="designEditForm.config.properties.mode"
                :options="['paginated', 'continuous', 'progressive']" :required="false" label="Mode">
              </b-form-select>
              <v-checkbox v-model="designEditForm.config.properties.showPageIndicators" label="Show page indicators">
              </v-checkbox>
            </template>
            <v-btn :color="designEditFormValid ? 'success' : 'info'" :disabled="!designEditFormValid"
              @click="submitDesignForm" style="text-align: center;" class="ma-1">SAVE
            </v-btn>
            <v-btn :color="'info'" @click="hideFormDesign(true)" style="text-align: center; margin-left: 10px;"
              class="ma-1">
              CANCEL
            </v-btn>
            <v-btn :color="'warning'" @click="designEditForm.config.properties = defaultProperties()"
              style="text-align: center; margin-left: 10px;" class="ma-1">
              RESET
            </v-btn>
          </template>
        </v-form>
        <div style="margin-top: 20px; width: 100%;">
          <div style="width: 100%;">Copy style from form:</div>
          <b-form-select @change="x => copyFormStyle(designEditFormId, x)" :options="formsList" :required="false"
            label="Form">
          </b-form-select>
        </div>
      </div>
    </YSIDEBAR>
    <div v-if="builderOpen" class="formBuilderUI" style="position: fixed;left: 0;bottom: 0; right: 0; top: 0;z-index: 9999988;opacity: 1; background: white; padding: 40px;">
      <h3>Go to <a href="https://tripetto.app/">https://tripetto.app/</a> to design your form, and export the config from there.</h3>
      <textarea v-model="builderCustomModel" style="width: 100%; height: calc(100% - 20px)" @change="builder.onChange()"></textarea>
    </div>
  </div>
</template>

<style scoped>
.paint-fancy-color,
.paint-fancy-bgcolor {
  background: linear-gradient(45deg, red, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red);
  /* background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333)); */
}

.paint-fancy-color {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paint-fancy-color:hover {
  background: linear-gradient(45deg, var(--light) 0%, var(--light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menuBreaker {
  -khtml-box-sizing: border-box;
  -khtml-transition: opacity 0.3s;
  -moz-box-sizing: border-box;
  -moz-transition: opacity 0.3s;
  -ms-box-sizing: border-box;
  -ms-transition: opacity 0.3s;
  -o-box-sizing: border-box;
  -o-transition: opacity 0.3s;
  -webkit-box-sizing: border-box;
  -webkit-transition: opacity 0.3s;
  background-color: rgba(255, 255, 255, 0.2);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  cursor: default;
  flex-grow: 0;
  height: 40px;
  margin: 8px 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  transition: opacity 0.3s;
  vertical-align: top;
  white-space: nowrap;
  width: 1px;
}

.menuItem>i {
  float: left;
  font-size: 20px;
  margin: 18px 18px;
}

.menuItem.menuText {
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  padding: 0 20px 0 20px;
}

.menuItem.menuText>.text {
  margin: 0
}

.menuItem {
  -khtml-box-sizing: border-box;
  -khtml-transition: color .1s ease-in-out;
  -moz-box-sizing: border-box;
  -moz-transition: color .1s ease-in-out;
  -ms-box-sizing: border-box;
  -ms-transition: color .1s ease-in-out;
  -o-box-sizing: border-box;
  -o-transition: color .1s ease-in-out;
  -webkit-box-sizing: border-box;
  -webkit-transition: color .1s ease-in-out;
  background-position: 17px 17px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  cursor: default;
  flex-grow: 0;
  height: 56px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  transition: color .1s ease-in-out;
  vertical-align: top;
  white-space: nowrap;
  min-width: 56px;
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { Builder } from "tripetto";
import TLib from "./lib";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";

import "tripetto-block-text";
import "tripetto-block-calculator";
import "tripetto-block-checkbox";
import "tripetto-block-checkboxes";
import "tripetto-block-date";
import "tripetto-block-device";
import "tripetto-block-dropdown";
import "tripetto-block-error";
import "tripetto-block-evaluate";
import "tripetto-block-hidden-field";
import "tripetto-block-matrix";
import "tripetto-block-multiple-choice";
import "tripetto-block-number";
import "tripetto-block-paragraph";
import "tripetto-block-password";
import "tripetto-block-phone-number";
import "tripetto-block-picture-choice";
import "tripetto-block-radiobuttons";
import "tripetto-block-rating";
import "tripetto-block-regex";
import "tripetto-block-scale";
import "tripetto-block-setter";
import "tripetto-block-statement";
import "tripetto-block-stop";
import "tripetto-block-textarea";
import "tripetto-block-url";
import "tripetto-block-variable";
import "tripetto-block-yes-no";
import "tripetto-block-email";
import "tripetto-block-file-upload";

export default {
  data() {
    return {
      builderCustomModel: '',
      builder: null,
      builderOpen: false,
      builderHasChanges: false,
      builderIsSaving: false,
      builderIsDeploying: false,
      builderEditFormId: null,
      builderEditForm: null,
      forms: null,
      hosts: null,
      configEditFormId: null,
      configEditForm: null,
      configEditFormValid: false,
      designEditFormId: null,
      designEditForm: null,
      designEditFormValid: false,
      formResponses: null,
      formResponsesFormId: null
    };
  },
  components: {
    KTPortlet,
    Loader,
    YSIDEBAR: Y_SIDEBAR,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    formsList() {
      if (this.forms === null) return [];
      return this.forms.map(x => {
        return {
          text: x.name,
          value: x.id
        };
      });
    },

    icon() {
      return this.layoutConfig().self.logo.favicon || '/favicon-primary.png';
    },

    hostsSelector() {
      return this.hosts.map(x => {
        return {
          text: `https://${ x.hostname }/${ (this.configEditForm || {}).urlKey || this.configEditFormId || '[FORM ID]' }`,
          value: x.id
        };
      });
    },
    selectedHostCanCustom() {
      if (this.$tools.isNullOrUndefined(this.configEditForm) || this.$tools.isNullOrUndefined(this.configEditForm.renderAppId)) return false;
      for (let host of this.hosts) {
        if (host.id !== this.configEditForm.renderAppId) continue;
        return host.canUseCustomUrl;
      }
      return false;
    }
  },
  methods: {
    copyFormStyle(toFormId, fromFormId) {
      for (let fromIndex = 0; fromIndex < this.forms.length; fromIndex++) {
        if (this.forms[fromIndex].id !== fromFormId) continue;
        for (let toIndex = 0; toIndex < this.forms.length; toIndex++) {
          if (this.forms[toIndex].id !== toFormId) continue;
          this.designEditForm.config.properties = this.forms[fromIndex].config.properties;
          this.submitDesignForm();
        }
      }
    },
    getUrlForForm(appId, formId) {
      for (let host of this.hosts) {
        if (host.id === appId) {
          for (let form of this.forms) {
            if (form.id === formId)
              return `https://${ host.hostname }/${ form.urlKey || formId }`;
          }
        }
      }
      return null;
    },
    deployDraft() {
      const self = this;
      self.builderIsDeploying = true;
      TLib.publishForm(self, self.$route.params.serviceId, self.builderEditFormId).then((x) => {
        self.updateData(x);
        self.builderEditForm = x;
        self.builderIsDeploying = false;
      });
    },
    deployDraftStraight(formId) {
      const self = this;
      self.builderIsDeploying = true;
      TLib.publishForm(self, self.$route.params.serviceId, formId).then((x) => {
        self.updateData(x);
        self.builderIsDeploying = false;
        self.$refs.formConfig.hide();
      });
    },
    hideFormConfig(actByButton = true) {
      this.configEditFormId = null;
      this.configEditForm = null;
      this.configEditFormValid = false;
      if (actByButton)
        this.$refs.formConfig.hide();
    },
    submitForm(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs.formConfigForm.validate()) return;

      const self = this;
      if (this.configEditFormId === null) {
        TLib.newForm(self, self.$route.params.serviceId, self.configEditForm.name, self.configEditForm.description, self.configEditForm.renderAppId).then((x) => {
          self.forms.push(x);
          self.editNewForm(x.id);
        });
      } else {
        if (!self.selectedHostCanCustom) {
          self.configEditForm.urlKey = null;
        }
        self.configEditForm.tasks[0].active = true;
        TLib.updateForm(self, self.$route.params.serviceId, self.configEditFormId, {
          name: self.configEditForm.name,
          description: self.configEditForm.description,
          active: self.configEditForm.active,
          renderAppId: self.configEditForm.renderAppId,
          urlKey: self.configEditForm.urlKey == '' ? null : self.configEditForm.urlKey,
          tasks: self.configEditForm.tasks
        }).then((x) => {
          self.updateData(x);
          self.$refs.formConfig.hide();
        });
      }
    },
    defaultProperties(xc = {}) {
      return {
        color: xc.color || undefined,
        autoFocus: xc.autoFocus || undefined,
        noBranding: xc.noBranding || undefined,
        showEnumerators: xc.showEnumerators || undefined,
        hideRequiredIndicator: xc.hideRequiredIndicator || undefined,
        showProgressbar: xc.showProgressbar || undefined,
        showScrollbar: xc.showScrollbar || undefined,
        mode: xc.mode || undefined,
        showPageIndicators: xc.showPageIndicators || undefined,
        direction: xc.direction || undefined,
        verticalAlignment: xc.verticalAlignment || undefined,
        hidePast: xc.hidePast || undefined,
        hideUpcoming: xc.hideUpcoming || undefined,
        showSeparateSubmit: xc.showSeparateSubmit || undefined,
        showPreviousButton: xc.showPreviousButton || undefined,
        showNavigation: xc.showNavigation || undefined,
        disableScrolling: xc.disableScrolling || undefined,
        display: xc.display || undefined,
        autoOpen: xc.autoOpen || undefined,
        fullWidth: xc.fullWidth || undefined,

        background: xc.background || {},
        font: xc.font || {},
        inputs: xc.inputs || {},
        buttons: xc.buttons || {},
        navigation: xc.navigation || {},
        avatar: xc.avatar || {},
        questions: xc.questions || {},
        answers: xc.answers || {},
        chatButton: xc.chatButton || {},
      };
    },
    editDesignForm(formId = null) {
      if (this.$tools.isNullOrUndefined(formId)) return;
      this.designEditFormId = null;
      this.designEditForm = null;
      this.designEditFormValid = false;

      this.designEditFormId = formId;
      for (let form of this.forms) {
        if (form.id === formId) {
          this.designEditForm = JSON.parse(JSON.stringify(form));
          if (!this.$tools.isNullOrUndefined(this.designEditForm.draftConfig)) {
            this.designEditForm.config = this.designEditForm.draftConfig;
          }
          this.designEditForm.config.properties = this.defaultProperties(this.designEditForm.config.properties);
          /*this.designEditForm.config.properties.background = this.designEditForm.config.properties.background || {};
          this.designEditForm.config.properties.font = this.designEditForm.config.properties.font || {};
          this.designEditForm.config.properties.inputs = this.designEditForm.config.properties.inputs || {};
          this.designEditForm.config.properties.buttons = this.designEditForm.config.properties.buttons || {};
          this.designEditForm.config.properties.navigation = this.designEditForm.config.properties.navigation || {};
          this.designEditForm.config.properties.avatar = this.designEditForm.config.properties.avatar || {};
          this.designEditForm.config.properties.questions = this.designEditForm.config.properties.questions || {};
          this.designEditForm.config.properties.answers = this.designEditForm.config.properties.answers || {};
          this.designEditForm.config.properties.chatButton = this.designEditForm.config.properties.chatButton || {};*/
          this.$refs.formConfig.hide();
          this.$refs.formDesign.show();
          return;
        }
      }
    },
    hideFormDesign(actByButton = true) {
      let tFormId = `${ this.designEditFormId || '' }`;
      this.designEditFormId = null;
      this.designEditForm = null;
      this.designEditFormValid = false;
      if (actByButton)
        this.$refs.formDesign.hide();
      if (tFormId !== '')
        this.editNewForm(tFormId);
    },
    parseColourInput(valu) {
      if (this.$tools.isObject(valu)) {
        //if (valu.alpha === 1 && valu.hexa === "#FF0000FF" && valu.hue === 0)
        //  return undefined;
        return valu.hexa;
      }
      return valu;
    },
    submitDesignForm(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs.formDesignForm.validate()) return;

      const self = this;
      let tempCopy = null;
      for (let form of this.forms) {
        if (form.id === self.designEditFormId) {
          tempCopy = JSON.parse(JSON.stringify(form));
          break;
        }
      }
      if (tempCopy === null) return;
      if (this.$tools.isNullOrUndefined(tempCopy.draftConfig)) {
        tempCopy.draftConfig = tempCopy.config;
      }
      tempCopy.draftConfig.properties = self.designEditForm.config.properties;
      tempCopy.draftConfig.renderType = self.designEditForm.config.renderType;

      tempCopy.draftConfig.properties.color = this.parseColourInput(tempCopy.draftConfig.properties.color);
      tempCopy.draftConfig.properties.background.color = this.parseColourInput(tempCopy.draftConfig.properties.background.color);

      if (this.$tools.isObject(tempCopy.draftConfig.properties.inputs)) {
        tempCopy.draftConfig.properties.inputs.backgroundColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.backgroundColor);
        tempCopy.draftConfig.properties.inputs.borderColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.borderColor);
        tempCopy.draftConfig.properties.inputs.textColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.textColor);
        tempCopy.draftConfig.properties.inputs.errorColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.errorColor);
        tempCopy.draftConfig.properties.inputs.agreeColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.agreeColor);
        tempCopy.draftConfig.properties.inputs.declineColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.declineColor);
        tempCopy.draftConfig.properties.inputs.selectionColor = this.parseColourInput(tempCopy.draftConfig.properties.inputs.selectionColor);
      }
      if (this.$tools.isObject(tempCopy.draftConfig.properties.buttons)) {
        tempCopy.draftConfig.properties.buttons.baseColor = this.parseColourInput(tempCopy.draftConfig.properties.buttons.baseColor);
        tempCopy.draftConfig.properties.buttons.textColor = this.parseColourInput(tempCopy.draftConfig.properties.buttons.textColor);
        tempCopy.draftConfig.properties.buttons.finishColor = this.parseColourInput(tempCopy.draftConfig.properties.buttons.finishColor);
      }
      if (this.$tools.isObject(tempCopy.draftConfig.properties.navigation)) {
        tempCopy.draftConfig.properties.navigation.backgroundColor = this.parseColourInput(tempCopy.draftConfig.properties.navigation.backgroundColor);
        tempCopy.draftConfig.properties.navigation.textColor = this.parseColourInput(tempCopy.draftConfig.properties.navigation.textColor);
        tempCopy.draftConfig.properties.navigation.progressbarColor = this.parseColourInput(tempCopy.draftConfig.properties.navigation.progressbarColor);
      }
      if (this.$tools.isObject(tempCopy.draftConfig.properties.questions)) {
        tempCopy.draftConfig.properties.questions.backgroundColor = this.parseColourInput(tempCopy.draftConfig.properties.questions.backgroundColor);
        tempCopy.draftConfig.properties.questions.borderColor = this.parseColourInput(tempCopy.draftConfig.properties.questions.borderColor);
        tempCopy.draftConfig.properties.questions.textColor = this.parseColourInput(tempCopy.draftConfig.properties.questions.textColor);
      }
      if (this.$tools.isObject(tempCopy.draftConfig.properties.answers)) {
        tempCopy.draftConfig.properties.answers.backgroundColor = this.parseColourInput(tempCopy.draftConfig.properties.answers.backgroundColor);
        tempCopy.draftConfig.properties.answers.borderColor = this.parseColourInput(tempCopy.draftConfig.properties.answers.borderColor);
        tempCopy.draftConfig.properties.answers.textColor = this.parseColourInput(tempCopy.draftConfig.properties.answers.textColor);
      }
      if (this.$tools.isObject(tempCopy.draftConfig.properties.chatButton)) {
        tempCopy.draftConfig.properties.chatButton.color = this.parseColourInput(tempCopy.draftConfig.properties.chatButton.color);
      }

      TLib.updateForm(self, self.$route.params.serviceId, self.designEditFormId, {
        name: tempCopy.name,
        description: tempCopy.description,
        active: tempCopy.active,
        renderAppId: tempCopy.renderAppId,
        urlKey: tempCopy.urlKey,
        tasks: tempCopy.tasks,
        config: tempCopy.draftConfig
      }).then((x) => {
        self.updateData(x);
        self.builderEditForm = x;
        self.$refs.formDesign.hide();
      });
    },
    updateData(x) {
      for (let formI = 0; formI < this.forms.length; formI++) {
        if (x.id === this.forms[formI].id) {
          this.forms[formI] = x;
          break;
        }
      }
    },
    editNewForm(formId = null) {
      this.configEditFormId = null;
      this.configEditForm = null;
      this.configEditFormValid = false;

      if (formId !== null) {
        this.configEditFormId = formId;
        for (let form of this.forms) {
          if (form.id === formId) {
            this.configEditForm = JSON.parse(JSON.stringify(form));
            this.$refs.formConfig.show();
            return;
          }
        }
      }
      this.configEditForm = {
        name: '',
        description: '',
        urlKey: null,
        renderAppId: this.hostsSelector[0].value,
        tasks: []
      };
      this.$refs.formConfig.show();
    },
    updateForms(serviceId) {
      const self = this;
      return new Promise(r => {
        self.$data.forms = null;
        self.$data.hosts = null;
        TLib.getFormsApps(self, serviceId).then(fa => {
          self.hosts = fa;
          TLib.getForms(self, serviceId).then(x => {
            self.forms = x;
            r();
          });
        });
      });
    },
    openBuilder(formId) {
      this.hideFormConfig();
      this.builderOpen = false;
      this.builderHasChanges = false;
      this.builderIsSaving = false;
      this.builderIsDeploying = false;
      this.builderEditForm = null;
      this.builderEditFormId = formId;
      for (let form of this.forms) {
        if (form.id === formId) {
          this.builderEditForm = JSON.parse(JSON.stringify(form));
          /*if (this.builderEditForm.draftConfig === null) {
            this.builderEditForm.draftConfig = this.builderEditForm.config;
          }*/
          if (!this.$tools.isNullOrUndefined(this.builderEditForm.draftConfig) && !this.$tools.isNullOrUndefined(this.builderEditForm.draftConfig.config))
            return this.builder.open(this.builderEditForm.draftConfig.config);
          this.builder.open(this.builderEditForm.config.config);
          return;
        }
      }
    },
    findAndReplaceFavIconLink(elem, matchAttr, matchValue, replaceWith) {
      if (elem.tagName.toLowerCase() === 'a') {
        if (elem.getAttribute(matchAttr) === matchValue) {
          if (replaceWith === undefined)
            return elem;
          if (replaceWith !== null) {
            replaceWith.setAttribute("class", elem.getAttribute("class"));
            elem.parentNode.replaceChild(replaceWith, elem);
          }
          else
            elem.parentNode.removeChild(elem);

          return null;
        }
      }
      if (elem.children.length > 0) {
        for (let child of elem.children) {
          let reso = this.findAndReplaceFavIconLink(child, matchAttr, matchValue, replaceWith);
          if (reso !== null) return reso;
        }
      }
      return null;
    }
  },
  /*watch: {
    $route() {
      let formId = this.$route.params.formId;
      if (this.selectedProfile !== profileId) {
        this.selectProfile(profileId);
      }
    }
  },*/
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`ws-communication-whatsapp`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Communication" },
      { title: "Forms" }
    ]);

    //console.log('MOUNTED:', this.$route.params.serviceId);
    this.updateForms(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateForms(x));
    /*self.$eventBus.on(`ws-communication-whatsapp`, x => {
      if (x.data.serviceId !== self.$route.params.serviceId) return;
    });*/

    let saveTimer = null;
    /*self.builder = {
      open(config) {
        self.builderCustomModel = JSON.stringify(config, ' ', 2);
        self.builderIsSaving = false;
        self.builderHasChanges = false;
        self.builderOpen = true;
      },
      onChange: () => {
        self.builderHasChanges = true;
        //clearTimeout(saveTimer);
        //saveTimer = setTimeout(() => {
        //  saveTimer = null;
        //  self.builder.save();
        //}, 5000);
      },
      save () {
        self.builderHasChanges = false;
        self.builderIsSaving = true;
        //clearTimeout(saveTimer);
        //saveTimer = null;

        let tempCopy = null;
        for (let form of self.forms) {
          if (form.id === self.builderEditFormId) {
            tempCopy = JSON.parse(JSON.stringify(form));
            break;
          }
        }
        if (tempCopy === null) return;
        if (self.$tools.isNullOrUndefined(tempCopy.draftConfig)) {
          tempCopy.draftConfig = tempCopy.config;
        }
        tempCopy.draftConfig.config = JSON.parse(self.builderCustomModel);

        //self.builderEditForm.draftConfig = JSON.parse(JSON.stringify(self.builderEditForm.config));
        //self.builderEditForm.draftConfig.config = d;
        TLib.updateForm(self, self.$route.params.serviceId, self.builderEditFormId, {
          name: tempCopy.name,
          description: tempCopy.description,
          active: tempCopy.active,
          renderAppId: tempCopy.renderAppId,
          urlKey: tempCopy.urlKey,
          tasks: tempCopy.tasks,
          config: tempCopy.draftConfig
        }).then(x => {
          self.updateData(x);
          self.builderEditForm = x;
          self.builderIsSaving = false;
        });
        self.$log.info(d);
        self.$log.info(JSON.stringify(d));
      },
      close () {
        self.builderCustomModel = '';
        self.builderOpen = false;
        self.editNewForm(self.builderEditFormId);
      }
    }*/
    self.builder = new Builder({
      element: document.getElementById("form-builder"),
      onReady: () => {
        self.$log.info('ready');
        //window.document.getElementById("form-builder").children[0].setAttribute("class", '');
        let newElemIcon = document.createElement("div");
        newElemIcon.setAttribute("style", `background-image: url(${ this.icon })`);
        //self.findAndReplaceFavIconLink(window.document.getElementById("form-builder"), "href", 'https://tripetto.com', newElemIcon);

        let newElemDocs = document.createElement("div");
        newElemDocs.innerHTML = '<i class="cjbcbnc cg1r1id"></i>';
        //self.findAndReplaceFavIconLink(window.document.getElementById("form-builder"), "href", 'https://docs.tripetto.com', newElemDocs);


        let tElem = self.findAndReplaceFavIconLink(window.document.getElementById("form-builder"), "href", 'https://tripetto.com');
        if (tElem !== null) {
          let tDelElem = tElem.parentElement.parentElement.parentElement;
          tDelElem.parentNode.removeChild(tDelElem);
        }
      },
      onOpen: () => {
        self.builderIsSaving = false;
        self.builderHasChanges = false;
        self.builderOpen = true;
      },
      onClose: () => {
        self.builderOpen = false;
        self.editNewForm(self.builderEditFormId);
      },
      onChange: () => {
        self.builderHasChanges = true;
        clearTimeout(saveTimer);
        saveTimer = setTimeout(() => {
          saveTimer = null;
          self.builder.save();
        }, 5000);
      },
      onSave: (d) => {
        self.builderHasChanges = false;
        self.builderIsSaving = true;
        clearTimeout(saveTimer);
        saveTimer = null;

        let tempCopy = null;
        for (let form of this.forms) {
          if (form.id === self.builderEditFormId) {
            tempCopy = JSON.parse(JSON.stringify(form));
            break;
          }
        }
        if (tempCopy === null) return;
        if (this.$tools.isNullOrUndefined(tempCopy.draftConfig)) {
          tempCopy.draftConfig = tempCopy.config;
        }
        tempCopy.draftConfig.config = d;

        //self.builderEditForm.draftConfig = JSON.parse(JSON.stringify(self.builderEditForm.config));
        //self.builderEditForm.draftConfig.config = d;
        TLib.updateForm(self, self.$route.params.serviceId, self.builderEditFormId, {
          name: tempCopy.name,
          description: tempCopy.description,
          active: tempCopy.active,
          renderAppId: tempCopy.renderAppId,
          urlKey: tempCopy.urlKey,
          tasks: tempCopy.tasks,
          config: tempCopy.draftConfig
        }).then(x => {
          self.updateData(x);
          self.builderEditForm = x;
          self.builderIsSaving = false;
        });
        self.$log.info(d);
        self.$log.info(JSON.stringify(d));
      }
    });
    window.builder = self.builder;
  }
};
</script>